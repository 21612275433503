import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/popper.js.org/popper.js.org/src/components/Layout.js";
import { css } from '@emotion/css';
import { media } from 'src/components/Framework';
import Logo from 'src/images/popper-logo-outlined.svg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "documentation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#documentation",
        "aria-label": "documentation permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Documentation`}</h1>

    <img src={Logo} alt="Popper" height={190} style={{
      float: 'right'
    }} className={css`
    float: right;
    position: relative;
    top: -15px;
    margin-left: 10px;
    width: 190px;
    height: 190px;
    filter: contrast(105%) brightness(95%);
    display: none;
    ${media.lg} {
      display: block;
    }
  `} />
    <p><strong parentName="p">{`Positioning tooltips and popovers is difficult. Popper is here to help!`}</strong></p>
    <p>{`Given an element, such as a button, and a tooltip element describing it, Popper
will automatically put the tooltip in the right place near the button.`}</p>
    <p>{`It will position `}<em parentName="p">{`any`}</em>{` UI element that "pops out" from the flow of your document
and floats near a target element. The most common example is a tooltip, but it
also includes popovers, drop-downs, and more. All of these can be generically
described as a "popper" element.`}</p>
    <p>{`If you are looking for the `}<a parentName="p" {...{
        "href": "/docs/v1/"
      }}>{`Popper v1 documentation, click here`}</a>{`.`}</p>
    <x-ad />
    <h2 {...{
      "id": "why-not-use-pure-css",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#why-not-use-pure-css",
        "aria-label": "why not use pure css permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why not use pure CSS?`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Clipping and overflow issues`}</strong>{`: Pure CSS poppers will not be prevented from
overflowing clipping boundaries, such as the viewport. It will get partially
cut off or overflows if it's near the edge since there is no dynamic
positioning logic. When using Popper, your popper will always be positioned in
the right place without needing manual adjustments.`}</li>
      <li parentName="ul"><strong parentName="li">{`No flipping`}</strong>{`: CSS poppers will not flip to a different placement to fit
better in view if necessary. While you can manually adjust for the main axis
overflow, this feature cannot be achieved via CSS alone. Popper automatically
flips the tooltip to make it fit in view as best as possible for the user.`}</li>
      <li parentName="ul"><strong parentName="li">{`No virtual positioning`}</strong>{`: CSS poppers cannot follow the mouse cursor or be
used as a context menu. Popper allows you to position your tooltip relative to
any coordinates you desire.`}</li>
      <li parentName="ul"><strong parentName="li">{`Slower development cycle`}</strong>{`: When pure CSS is used to position popper
elements, the lack of dynamic positioning means they must be carefully placed
to consider overflow on all screen sizes. In reusable component libraries,
this means a developer can't just add the component anywhere on the page,
because these issues need to be considered and adjusted for every time. With
Popper, you can place your elements anywhere and they will be positioned
correctly, without needing to consider different screen sizes, layouts, etc.
This massively speeds up development time because this work is automatically
offloaded to Popper.`}</li>
      <li parentName="ul"><strong parentName="li">{`Lack of extensibility`}</strong>{`: CSS poppers cannot be easily extended to fit any
arbitrary use case you may need to adjust for. Popper is built with
extensibility in mind.`}</li>
    </ul>
    <h2 {...{
      "id": "why-popper",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#why-popper",
        "aria-label": "why popper permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why Popper?`}</h2>
    <p>{`With the CSS drawbacks out of the way, we now move on to Popper in the
JavaScript space itself.`}</p>
    <p>{`Naive JavaScript tooltip implementations usually have the following problems:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Scrolling containers`}</strong>{`: They don't ensure the tooltip stays with the
reference element while scrolling when inside any number of scrolling
containers.`}</li>
      <li parentName="ul"><strong parentName="li">{`DOM context`}</strong>{`: They often require the tooltip move outside of its original
DOM context because they don't handle `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`offsetParent`}</code>{` contexts.`}</li>
      <li parentName="ul"><strong parentName="li">{`Compatibility`}</strong>{`: Popper handles an incredible number of edge cases regarding
different browsers and environments (mobile viewports, RTL, scrollbars enabled
or disabled, etc.). Popper is a popular and well-maintained library, so you
can be confident positioning will work for your users on any device.`}</li>
      <li parentName="ul"><strong parentName="li">{`Configurability`}</strong>{`: They often lack advanced configurability to suit any
possible use case.`}</li>
      <li parentName="ul"><strong parentName="li">{`Size`}</strong>{`: They are usually relatively large in size, or require an ancient
jQuery dependency.`}</li>
      <li parentName="ul"><strong parentName="li">{`Performance`}</strong>{`: They often have runtime performance issues and update the
tooltip position too slowly.`}</li>
    </ul>
    <p><strong parentName="p">{`Popper solves all of these key problems in an elegant, performant manner.`}</strong>{` It
is a lightweight ~3 kB library that aims to provide a reliable and extensible
positioning engine you can use to ensure all your popper elements are positioned
in the right place.`}</p>
    <p>{`When you start writing your own popper implementation, you'll quickly run into
all of the problems mentioned above. These widgets are incredibly common in our
UIs; we've done the hard work figuring this out so you don't need to spend hours
fixing and handling numerous edge cases that we already ran into while building
the library!`}</p>
    <p>{`Popper is used in popular libraries like Bootstrap, Foundation, Material UI, and
more. It's likely you've already used popper elements on the web positioned by
Popper at some point in the past few years.`}</p>
    <p>{`Since we write UIs using powerful abstraction libraries such as React or Angular
nowadays, you'll also be glad to know Popper can fully integrate with them and
be a good citizen together with your other components. Check out `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`react-popper`}</code>{`
for the official Popper wrapper for React.`}</p>
    <h2 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h2>
    <h3 {...{
      "id": "1-package-manager",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#1-package-manager",
        "aria-label": "1 package manager permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Package Manager`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# With npm`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` i @popperjs/core

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# With Yarn`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`yarn`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` @popperjs/core`}</code></pre></div>
    <h3 {...{
      "id": "2-cdn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2-cdn",
        "aria-label": "2 cdn permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. CDN`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- Development version -->`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://unpkg.com/@popperjs/core@2/dist/umd/popper.js`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- Production version -->`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://unpkg.com/@popperjs/core@2`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h3 {...{
      "id": "3-direct-download",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#3-direct-download",
        "aria-label": "3 direct download permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Direct Download?`}</h3>
    <p>{`Managing dependencies by "directly downloading" them and placing them into your
source code is not recommended for a variety of reasons, including missing out
on feat/fix updates easily. Please use a versioning management system like a CDN
or npm/Yarn.`}</p>
    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Usage`}</h2>
    <p>{`The most straightforward way to get started is to import Popper from the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`unpkg`}</code>{`
CDN, which includes all of its features. You can call the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Popper.createPopper`}</code>{`
constructor to create new popper instances.`}</p>
    <p>{`Here is a complete example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token doctype"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<!`}</span><span parentName="span" {...{
              "className": "token doctype-tag"
            }}>{`DOCTYPE`}</span>{` `}<span parentName="span" {...{
              "className": "token name"
            }}>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`title`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Popper example`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`title`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`style`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token style"
          }}><span parentName="span" {...{
              "className": "token language-css"
            }}>{`
  `}<span parentName="span" {...{
                "className": "token selector"
              }}>{`#tooltip`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
    `}<span parentName="span" {...{
                "className": "token property"
              }}>{`background-color`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` #333`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
    `}<span parentName="span" {...{
                "className": "token property"
              }}>{`color`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` white`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
    `}<span parentName="span" {...{
                "className": "token property"
              }}>{`padding`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` 5px 10px`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
    `}<span parentName="span" {...{
                "className": "token property"
              }}>{`border-radius`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` 4px`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
    `}<span parentName="span" {...{
                "className": "token property"
              }}>{`font-size`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` 13px`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
  `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`
`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`style`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`button`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`button`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-describedby`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`tooltip`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`I'm a button`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`tooltip`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`role`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`tooltip`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`I'm a tooltip`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://unpkg.com/@popperjs/core@2`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}><span parentName="span" {...{
              "className": "token language-javascript"
            }}>{`
  `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`const`}</span>{` button `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` document`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`querySelector`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`'#button'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
  `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`const`}</span>{` tooltip `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` document`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`querySelector`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`'#tooltip'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`

  `}<span parentName="span" {...{
                "className": "token comment"
              }}>{`// Pass the button, the tooltip, and some options, and Popper will do the`}</span>{`
  `}<span parentName="span" {...{
                "className": "token comment"
              }}>{`// magic positioning for you:`}</span>{`
  Popper`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`createPopper`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`button`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` tooltip`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
    placement`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'right'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
  `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Visit the `}<a parentName="p" {...{
        "href": "tutorial/"
      }}>{`tutorial`}</a>{` for an example of how to build your own tooltip
from scratch using Popper.`}</p>
    <h3 {...{
      "id": "module-bundlers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#module-bundlers",
        "aria-label": "module bundlers permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Module bundlers`}</h3>
    <p>{`You can import the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`createPopper`}</code>{` constructor from the fully-featured file:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` createPopper `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'@popperjs/core'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` button `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'#button'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` tooltip `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'#tooltip'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Pass the button, the tooltip, and some options, and Popper will do the`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// magic positioning for you:`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`createPopper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`button`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` tooltip`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  placement`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'right'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`All the modifiers listed in the docs menu will be enabled and "just work", so
you don't need to think about setting Popper up. The size of Popper including
all of its features is about 5 kB minzipped, but it may grow a bit in the
future.`}</p>
    <h4 {...{
      "id": "popper-lite-tree-shaking",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#popper-lite-tree-shaking",
        "aria-label": "popper lite tree shaking permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Popper Lite (tree-shaking)`}</h4>
    <p>{`If bundle size is important, you'll want to take advantage of tree-shaking. The
library is built in a modular way to allow to import only the parts you really
need.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` createPopper `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'@popperjs/core/lib/popper-lite.js'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`The Lite version includes the most necessary modifiers that will compute the
offsets of the popper, compute and add the positioning styles, and add event
listeners. This is close in bundle size to pure CSS tooltip libraries, and
behaves somewhat similarly.`}</p>
    <p>{`However, this does not include the features that makes Popper truly useful.`}</p>
    <p>{`The two most useful modifiers not included in Lite are `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`preventOverflow`}</code>{` and
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flip`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` createPopper `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'@popperjs/core/lib/popper-lite.js'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` preventOverflow `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'@popperjs/core/lib/modifiers/preventOverflow.js'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` flip `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'@popperjs/core/lib/modifiers/flip.js'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` button `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'#button'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` tooltip `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'#tooltip'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`createPopper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`button`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` tooltip`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  modifiers`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`preventOverflow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` flip`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`As you make more poppers, you may be finding yourself needing other modifiers
provided by the library.`}</p>
    <p>{`See `}<a parentName="p" {...{
        "href": "tree-shaking/"
      }}>{`tree-shaking`}</a>{` for more information.`}</p>
    <h2 {...{
      "id": "distribution-targets",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#distribution-targets",
        "aria-label": "distribution targets permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Distribution targets`}</h2>
    <p>{`Popper is distributed in 3 different versions, in 3 different file formats.`}</p>
    <p>{`The 3 file formats are:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`esm`}</code>{` (works with `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`import`}</code>{` syntax — `}<strong parentName="li">{`recommended`}</strong>{`)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`umd`}</code>{` (works with `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`<script>`}</code>{` tags or RequireJS)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`cjs`}</code>{` (works with `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`require()`}</code>{` syntax)`}</li>
    </ul>
    <p>{`There are two different `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`esm`}</code>{` builds, one for bundler consumers (e.g. webpack,
Rollup, etc..), which is located under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/lib`}</code>{`, and one for browsers with native
support for ES Modules, under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/dist/esm`}</code>{`. The only difference within the two,
is that the browser-compatible version doesn't make use of
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`process.env.NODE_ENV`}</code>{` to run development checks.`}</p>
    <p>{`The 3 versions are:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`popper`}</code>{`: includes all the modifiers (features) in one file (`}<strong parentName="li">{`default`}</strong>{`);`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`popper-lite`}</code>{`: includes only the minimum amount of modifiers to provide the
basic functionality;`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`popper-base`}</code>{`: doesn't include any modifier, you must import them separately;`}</li>
    </ul>
    <p>{`Below you can find the size of each version, minified and compressed with the
`}<a parentName="p" {...{
        "href": "https://medium.com/groww-engineering/enable-brotli-compression-in-webpack-with-fallback-to-gzip-397a57cf9fc6"
      }}>{`Brotli compression algorithm`}</a>{`:`}</p>
    {
      /* Don't change the labels to use hyphens, it breaks, even when encoded */
    }
    <p><img parentName="p" {...{
        "src": "https://img.badgesize.io/https://unpkg.com/@popperjs/core/dist/umd/popper.min.js?compression=brotli&label=popper",
        "alt": null
      }}></img>{`
`}<img parentName="p" {...{
        "src": "https://img.badgesize.io/https://unpkg.com/@popperjs/core/dist/umd/popper-lite.min.js?compression=brotli&label=popper%20lite",
        "alt": null
      }}></img>{`
`}<img parentName="p" {...{
        "src": "https://img.badgesize.io/https://unpkg.com/@popperjs/core/dist/umd/popper-base.min.js?compression=brotli&label=popper%20base",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      